@import 'Styles/includes';

.LoopcheckList {
    $root: &;

    border-bottom: 1px solid $grey-20;

    &__Header {
        @include textstyle(body-regular);

        position: relative;
        padding-bottom: 16px;
    }

    &__Title {
        @include textstyle(h3);

        color: $red;
    }

    &__Website {
        @include textstyle(body-regular);

        display: inline-block;
        margin-top: 8px;
        text-decoration: underline;
        color: $red;
        font-weight: $bold;

        &:hover {
            text-decoration: none;
            color: $link-hover-color;
        }

        &::after {
            content: none;
        }

        @include media(M) {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
        }
    }

    &__Item {
        border-top: 1px solid $grey-20;
        padding: 16px 0;

        @include media(M) {
            padding: 32px 0;
        }
    }

    &__Footer {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 24px;

        @include media(M) {
            padding-bottom: 40px;
        }
    }
}
