$assetsPath: '../../public' !default;
$fontsPath: '#{$assetsPath}/fonts/';

$breakpoints: (
    S: 375px,
    SL: 500px, // Landscape
    M: 768px,
    ML: 1024px, // Landscape
    L: 1280px,
    XL: 1440px,
    XXL: 1680px,
    wp-sm: 601px,
    wp-md: 783px,
);

$maxwidths: (
    content: 640px,
    article: 1092px,
    site: 1318px + 60px + 60px,
);

$spacing: (
    d: 20px,
    M: 40px,
    XL: 60px,
);

$transition: .3s ease-in-out;

// Colors
$grey-95: #131313;
$grey-90: #212121;
$grey-80: #424242;
$grey-70: #616161;
$grey-60: #757575;
$grey-50: #9e9e9e;
$grey-40: #bdbdbd;
$grey-30: #e0e0e0;
$grey-20: #eee;
$grey-10: #f5f5f5;
$grey-5: #fafafa;

$red: #cf171a;
$red-60: #b51417;
$red-20: #f1babb;
$red-10: #fae3e1;
$red-5: #fdf4f2;

$blue: #0867f2;
$blue-70: #0e38c7;
$blue-40: #9cc2fa;
$blue-20: #bfd5f6;
$blue-10: #eff5ff;

$green: #41a173;
$green-5: #e9f6f0;
$green-10: #dff4eb;

$yellow: #f8ae0d;

// Font families
$font-aktiv: 'aktiv-grotesk', Arial, sans-serif;

$base-font: $font-aktiv;

$base-color: $grey-90;
$link-color: $red;
$link-hover-color: $red-60;
$error-color: $red;
$error-bg: $red-5;

$z-Header__Mega: 10;
$z-SidebarMenu__List: 20;
$z-CookieScript__Badge: 50;
$z-Popup: 60; // Must be above cookie script badge and below popup
$z-CookieScript__Popup: 70;
$z-Header__MobileMenu: 100;
$z-Header--Mega: 100;

// Font weights
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;
$heavy: 900;

// No other font sizes are needed
// Font sizes and line heights as defined by styleguide in pixels
$textstyles: (
    h1: (
        d: (font-size: 38, line-height: 48, font-weight: $bold),
        M: (font-size: 54, line-height: 68),
    ),
    h2: (
        d: (font-size: 32, line-height: 40, font-weight: $bold),
        M: (font-size: 46, line-height: 54),
    ),
    h2-wysiwyg: (
        d: (font-size: 32, line-height: 40, font-weight: $bold),
        M: (font-size: 38, line-height: 48),
    ),
    h3: (
        d: (font-size: 24, line-height: 36, font-weight: $bold),
        M: (font-size: 32, line-height: 40),
    ),
    h3-wysiwyg: (
        d: (font-size: 26, line-height: 36, font-weight: $bold),
        M: (font-size: 32, line-height: 38),
    ),
    h4: (
        d: (font-size: 20, line-height: 28, font-weight: $bold),
        M: (font-size: 28, line-height: 32),
    ),
    h4-wysiwyg: (
        d: (font-size: 22, line-height: 28, font-weight: $bold),
        M: (font-size: 26, line-height: 32),
    ),
    h5: (
        d: (font-size: 18, line-height: 24, font-weight: $bold),
        M: (font-size: 24, line-height: 30),
    ),
    h5-wysiwyg: (
        d: (font-size: 18, line-height: 26, font-weight: $bold),
        M: (font-size: 22, line-height: 28),
    ),
    h6: (
        d: (font-size: 16, line-height: 20, font-weight: $bold),
        M: (font-size: 18, line-height: 24),
    ),
    h6-wysiwyg: (
        d: (font-size: 16, line-height: 20, font-weight: $bold),
        M: (font-size: 18, line-height: 26),
    ),
    body-large: (
        d: (font-size: 18, line-height: 24),
        M: (font-size: 20, line-height: 28),
    ),
    body-regular: (
        d: (font-size: 16, line-height: 24),
        M: (font-size: 18, line-height: 28),
    ),
    body-small: (
        d: (font-size: 14, line-height: 21),
        M: (font-size: 16, line-height: 24),
    ),
    preamble: (
        d: (font-size: 18, line-height: 28, font-weight: $medium),
        M: (font-size: 20, line-height: 31),
    ),
    quote: (
        d: (font-size: 20, line-height: 28, font-weight: $bold),
        M: (font-size: 24, line-height: 30),
    ),
    caption: (
        d: (font-size: 12, line-height: 15, color: $grey-70),
    ),
    reference: (
        d: (font-size: 12, line-height: 18),
        M: (font-size: 14, line-height: 21),
    ),
);
