@import 'Styles/includes';

.Fieldset {
    $root: &;

    margin-bottom: 8px;

    &:last-of-type {
        margin-bottom: 16px;
    }

    &__Legend {
        @include textstyle(h6);

        margin-bottom: 24px;
    }

    &__Description {
        @include textstyle(body-small);

        margin-bottom: 24px;

        #{$root}__Legend + & {
            margin-top: -16px;
        }

        * {
            margin-bottom: 8px;
        }
    }
}
