@import 'Styles/includes';

.LocalList {
    $root: &;

    border-top: 1px solid $grey-20;

    &__Item {
        border-bottom: 1px solid $grey-20;
        padding: 16px 0;

        @include media(M) {
            padding: 32px 0;
        }
    }
}
