@import 'Styles/includes';

.RadioButton {
    $root: &;

    &__Input {
        @extend %form__radio;
    }

    &__Label {
        @extend %form__label-radio;
        @extend %form__label-radio--wrapped;

        #{$root}__Input:checked + & {
            @extend %form__label-radio--checked;
            @extend %form__label-radio--wrapped--checked;
        }

        #{$root}--Error & {
            @extend %form__label-radio--error;
            @extend %form__label-radio--wrapped--error;
        }

        &--FullWidth {
            width: 100%;
            margin-bottom: 16px;
        }
    }
}
