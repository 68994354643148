@import 'Styles/includes';

.CardTreatment {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 4px;
    background: $red-5;

    &__Link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        z-index: 1;
    }

    &__Image {
        position: relative;
        width: 100%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            height: 0;
            width: 100%;
            padding-bottom: #{percentage(9/16)};
        }

        #{$root}--HasImage &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background: black;
            opacity: .05;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% + 1px); // Prevent image from jumping on hover
            object-fit: cover;
            object-position: center center;
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
            transition: transform $transition;

            #{$root}:hover & {
                transform: translate3d(0, 0, 0) scale3d(1.05, 1.05, 1);
            }
        }
    }

    &__Content {
        flex: 1 0 auto;
        position: relative;
        padding: 32px 16px 72px;
        color: $grey-90;

        @include media(M) {
            padding: 32px 24px 80px;
        }

        #{$root}--HasImage & {
            padding-top: 24px;
        }
    }

    &__Meta {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        font-size: 1.2rem;
        line-height: 1.4;
    }

    &__Term {
        color: $red;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__Title {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.4;
    }

    &__Text {
        margin-top: 16px;
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 1.5;
    }

    &__Button {
        @extend %button--round-arrow-white;

        position: absolute !important;
        bottom: 16px;
        right: 16px;

        @include media(M) {
            bottom: 24px;
            right: 24px;
        }

        #{$root}:hover & {
            @extend %button--round-arrow-white, :hover;
        }
    }
}
