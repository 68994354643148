@use 'sass:math';

@mixin wrap {
    padding-left: map-get($spacing, d);
    padding-right: map-get($spacing, d);
    max-width: map-get($maxwidths, site);
    margin-left: auto;
    margin-right: auto;

    @include media(M) {
        padding-left: map-get($spacing, M);
        padding-right: map-get($spacing, M);
    }

    @include media(L) {
        padding-left: map-get($spacing, L);
        padding-right: map-get($spacing, L);
    }
}

@mixin href-external() {
    &:not([href^="mailto:"]):not([href^="tel:"]):not([href=""]):not([href^="#"]):not([href^="/"]):not([href^="http://hrf.se"]):not([href*="beta.hrf.se"]):not([href*="https://hrf.se"]):not([href*="www.hrf.se"]):not([href*="stage.hrf.se"]) {
        @content;
    }
}

@mixin href-download() {
    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".docx"],
    &[href$=".ppt"],
    &[href$=".pptx"],
    &[href$=".xls"],
    &[href$=".xlsx"],
    &[href$=".odt"],
    &[href$=".eps"],
    &[href$=".zip"] {
        @content;
    }
}

@mixin media($key) {
    @if $key == 'd' {
        @content;
    }
    @else if map-has-key($breakpoints, $key) {
        @media (min-width: map-get($breakpoints, $key)) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin media-max($key) {
    @if $key == 'd' {
        @content;
    }
    @else if map-has-key($breakpoints, $key) {
        @media (max-width: map-get($breakpoints, $key)-1) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin media-min-max($minKey, $maxKey) {
    @if $minKey == 'd' or $maxKey == 'd' {
        @content;
    }
    @else if map-has-key($breakpoints, $minKey) and map-has-key($breakpoints, $maxKey) {
        @media (min-width: map-get($breakpoints, $minKey)) and (max-width: map-get($breakpoints, $maxKey)-1) {
            @content;
        }
    } @else {
        @error 'Unfortunately, no value could be retrieved from `#{$breakpoints}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin clearfix() {
    &::after,
    &::before {
        content: '';
        display: table;
        clear: both;
        width: 100%;
    }
}

// For setting font-size and line-height on elements defined by variabels, including media queries
// Usage: @include textstyle(h1);
@mixin textstyle($key) {

    // Loop through text map and set font-size and line-height for each media query
    @if map-has-key($textstyles, $key) {
        $map: map-get($textstyles, $key);

        @each $media, $style in $map {
            @include media($media) {
                @if map-has-key($style, font-size) and map-has-key($style, line-height) {
                    $font-size: map-get($style, font-size);
                    $line-height: map-get($style, line-height);

                    font-size: #{math.div($font-size, 10)}rem;
                    line-height: #{math.div(round(math.div($line-height, $font-size) * 100), 100)};
                }

                @each $param, $value in $style {
                    @if $param != font-size and $param != line-height {
                        #{$param}: $value;
                    }
                }
            }
        }
    }
}
