@import 'Styles/includes';

.StepContainer {
    $root: &;

    scroll-margin-top: 106px;
    margin-bottom: 32px;
    border-radius: 4px;
    transition: box-shadow 0.2s;

    &:last-child {
        margin-bottom: 0;
    }

    &--Active {
        box-shadow: 2px 16px 32px 0 rgba(black, .12);
    }

    &__Header {
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 0 60px;
        border-radius: 4px;
        background-color: $grey-10;
        transition: all 0.2s;

        @include media(M) {
            padding: 28px 24px 28px 72px;
        }

        #{$root}--Finished & {
            background-color: $green-10;
        }

        #{$root}--Active & {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background-color: $blue-10;
        }
    }

    &__TitleContainer {
        display: flex;
        align-items: center;
    }

    &__Title {
        margin-bottom: 0;
        font-size: 2rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        position: relative;

        @include media(M) {
            font-size: 2.4rem;
        }

        &::before {
            position: absolute;
            left: -42px;
            width: 24px;
            height: 24px;
            border-radius: 32px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: $grey-30;
            font-size: 1.6rem;

            @include media(M) {
                left: -48px;
                width: 32px;
                height: 32px;
            }

            #{$root}--Active & {
                background-color: $blue;
                color: #fff;
            }

            #{$root}--Finished & {
                background-color: $green;
                color: transparent;
                background-image: url(#{$assetsPath}/img/check--white.svg);
                background-position: center;
                background-size: 12px;
                background-repeat: no-repeat;
            }

            #{$root}:nth-child(1) & {
                content: '1';
            }

            #{$root}:nth-child(2) & {
                content: '2';
            }

            #{$root}:nth-child(3) & {
                content: '3';
            }

            #{$root}:nth-child(4) & {
                content: '4';
            }

            #{$root}:nth-child(5) & {
                content: '5';
            }
        }
    }

    &__Notice {
        display: none;
        border-radius: 2px;
        height: 24px;
        margin-left: 12px;
        align-items: center;
        padding: 0 8px;
        background-color: #fff;
        font-size: 1.2rem;
        font-weight: bold;

        @include media(M) {
            display: inline-flex;
        }
    }

    &__Animate {
        #{$root}--Active & {
            overflow: visible;
        }
    }

    &__Content {
        background-color: #fff;
        padding: 24px 20px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        @include media(M) {
            padding-left: 32px;
            padding-right: 32px;
        }

        @include media(ML) {
            padding: 24px 40px 52px;
        }

        @include media(L) {
            padding-left: 64px;
            padding-right: 64px;
        }

        @include media(XL) {
            padding-left: 75px;
            padding-right: 75px;
        }
    }

    &__Alter {
        color: $grey-80;
        text-decoration: underline;
        font-size: 1.4rem;
    }
}
