@import 'Styles/includes';

.CardFact {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 4px;
    background: $red-5;

    &__Link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        z-index: 1;
    }

    &__Content {
        flex: 1 0 auto;
        position: relative;
        padding: 32px 16px 72px;
        color: $grey-90;

        @include media(M) {
            padding: 32px 24px 80px;
        }

        #{$root}--HasImage & {
            padding-top: 24px;
        }

        #{$root}--Full & {
            padding: 20px 16px 22px;

            @include media(M) {
                padding: 28px 88px 28px 24px;
            }

            @include media(M) {
                padding-right: 104px;
            }
        }
    }

    &__Meta {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        font-size: 1.2rem;
        line-height: 1.4;

        #{$root}--Full#{$root}--Parent & {
            display: none;
        }
    }

    &__Term {
        color: $red;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__Title {
        font-weight: $bold;

        #{$root}--Post & {
            font-size: 2rem;
            line-height: 1.4;
        }

        #{$root}--Parent & {
            font-size: 3rem;
            line-height: 1.33;

            @include media(M) {
                font-size: 3.6rem;
            }
        }
    }

    &__Text {
        max-width: 616px;
        margin-top: 16px;
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 1.5;

        #{$root}--Full#{$root}--Post & {
            display: none;
        }

        #{$root}--Parent & {
            margin-top: 12px;
            font-size: 1.8rem;
            line-height: 1.65;
        }
    }

    &__Button {
        @extend %button--round-arrow-white;

        position: absolute !important;
        bottom: 16px;
        right: 16px;

        @include media(M) {
            bottom: 24px;
            right: 24px;
        }

        #{$root}:hover & {
            @extend %button--round-arrow-white, :hover;
        }

        #{$root}--Full & {
            display: none;

            @include media(M) {
                display: block;
                top: 0;
                bottom: 0;
                right: 24px;
                margin: auto;
            }

            @include media(L) {
                right: 40px;
            }
        }
    }
}
