%richtext {
    p {
        margin-top: 12px;
        margin-bottom: 12px;

        @include media(M) {
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }

    ol,
    ul,
    hr,
    figure,
    blockquote,
    .preamble,
    .factbox,
    .wp-block-sage-preamble,
    .wp-block-sage-emphasis,
    .iframe,
    .section-image,
    .wp-block-media-text,
    .wp-block-table {
        margin-top: 24px;
        margin-bottom: 24px;

        @include media(M) {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    h2 {
        margin-top: 24px;
        margin-bottom: 8px;

        @include media(M) {
            margin-top: 40px;
            margin-bottom: 16px;
        }
    }

    h3 {
        margin-top: 24px;
        margin-bottom: 8px;

        @include media(M) {
            margin-top: 40px;
            margin-bottom: 14px;
        }
    }

    h4,
    h5,
    h6 {
        margin-top: 24px;
        margin-bottom: 8px;

        @include media(M) {
            margin-top: 40px;
            margin-bottom: 12px;
        }
    }

    p {
        @include textstyle(body-regular);

        &:empty {
            display: none;
        }
    }

    .preamble,
    .wp-block-sage-preamble {
        @include textstyle(preamble);

        &:first-child {
            margin-top: 0;
        }
    }

    a:not(.add_list_item) {
        color: $link-color;
        text-decoration: underline;
        transition: color $transition;

        &:hover {
            color: $link-hover-color;
            text-decoration: none;
        }

        @include href-external {
            &::after {
                content: '';
                display: inline-block;
                position: relative;
                top: 1px;
                width: 15px;
                height: 15px;
                margin-left: 5px;
                background: url('#{$assetsPath}/img/external--red.svg') no-repeat center center;
                background-size: contain;
            }
        }

        @include href-download {
            &::after {
                content: '';
                display: inline-block;
                position: relative;
                top: 1px;
                width: 15px;
                height: 15px;
                margin-left: 5px;
                background: url('#{$assetsPath}/img/download--red.svg') no-repeat center center !important;
                background-size: contain !important;
            }
        }

        &[href^="tel:"] {
            &::before {
                content: '';
                display: inline-block;
                position: relative;
                top: 2px;
                width: 20px;
                height: 20px;
                margin: -2px 5px -2px 0;
                background: url('#{$assetsPath}/img/phone--red.svg') no-repeat center center !important;
                background-size: contain !important;
            }
        }

        @media print {
            &::after {
                content: " [" attr(href) "] " !important;
                position: relative !important;
                display: inline !important;
                top: auto !important;
                bottom: auto !important;
                left: auto !important;
                right: auto !important;
                color: $base-color !important;
                background: none !important;
                opacity: 1 !important;
            }
        }
    }

    strong,
    b {
        font-weight: $bold;
    }

    i,
    em {
        font-style: italic;
    }

    hr {
        clear: both;
        width: 100%;
        height: 1px;
        border: none;
        background: $grey-30;
    }

    h1,
    h2 {
        @include textstyle(h2-wysiwyg);
    }

    h3 {
        @include textstyle(h3-wysiwyg);
    }

    h4 {
        @include textstyle(h4-wysiwyg);
    }

    h5 {
        @include textstyle(h5-wysiwyg);
    }

    h6 {
        @include textstyle(h6-wysiwyg);
    }

    ul,
    ol {
        &:not(.gform_fields):not(.gfield_radio):not(.gfield_checkbox) {
            @include textstyle(body-regular);
        }
    }

    ul {
        &:not(.gform_fields):not(.gfield_radio):not(.gfield_checkbox) {
            list-style-type: square !important;
            padding-left: 33px;

            li {
                margin-bottom: 12px;
                padding-left: 10px;

                &::marker {
                    color: $red;
                }
            }
        }
    }

    ol {
        &:not(.gform_fields):not(.gfield_radio):not(.gfield_checkbox) {
            list-style-type: decimal;
            padding-left: 32px;

            li {
                margin-bottom: 12px;
                padding-left: 11px;

                &::marker {
                    color: $red;
                    font-weight: $bold;
                }
            }
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    figure {
        max-width: 100%;
        height: auto;

        figcaption {
            font-size: 12px;
            margin-top: 9px;
        }
    }

    blockquote {
        font-weight: bold;
        padding-left: 24px;
        padding-top: 20px;
        position: relative;

        &::before {
            content: '';
            background-image: url(#{$assetsPath}/img/quote--red.svg);
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0.1;
            width: 56px;
            height: 48px;
        }

        p {
            margin: 0;
            font-size: 24px;
            line-height: 1.42;
            margin-bottom: 8px;
        }

        cite {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
        }
    }

    iframe {
        max-width: 100%;
        height: auto;
    }

    .factbox,
    .wp-block-sage-emphasis {
        @include clearfix;

        padding: 24px;
        background: $red-5;

        @include media(M) {
            padding: 32px;
        }

        *:first-child {
            margin-top: 0;
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    .block-editor-block-list__block {
        ul {
            list-style: none;
        }
    }

    .wp-block[data-align="left"] > .wp-block-image,
    .alignleft {
        display: inline;
        float: left;
        margin-right: 16px;
    }

    .wp-block[data-align="right"] > .wp-block-image,
    .alignright {
        display: inline;
        float: right;
        margin-left: 16px;
    }

    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    figure {
        &.alignleft,
        &.alignright {
            margin-bottom: 8px;
        }
    }

    .wp-block-table {
        @include textstyle(body-regular);

        max-width: 100%;
        overflow-x: auto;

        table {
            border-collapse: collapse;
            width: 100%;

            &.has-fixed-layout {
                table-layout: fixed;
                width: 100%;
            }
        }

        thead {
            font-weight: $bold;
            border: none;

            th,
            td {
                background: $red-10;
            }
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    td,
                    th {
                        background: $red-5;
                    }
                }

                &:nth-child(even) {
                    td,
                    th {
                        background: white;
                    }
                }
            }
        }

        tfoot {
            border: none;

            th,
            td {
                border-top: 1px solid black;
            }
        }

        td,
        th {
            padding: 8px 16px;
            border: none;

            &.has-text-align-left {
                text-align: left;
            }

            &.has-text-align-right {
                text-align: right;
            }

            &.has-text-align-center {
                text-align: center;
            }
        }

        th {
            font-weight: $bold;
        }
    }

    // Classic editor migrated section to section-image,
    // and block which is converted to
    .section-image,
    .wp-block-media-text {
        display: flex;
        flex-direction: column;

        @include media(SL) {
            flex-direction: row;
        }

        &.has-media-on-the-right {
            @include media(SL) {
                flex-direction: row-reverse;
            }

            .section-image__content,
            .wp-block-media-text__content {
                @include media(SL) {
                    padding-top: 0;
                    padding-left: 0;
                    padding-right: 16px;
                }
            }
        }

        &__media {
            flex: 0 0 auto;
            width: 150px !important;
            margin: 0 !important;

            @include media(M) {
                width: 200px !important;
            }
        }

        &__image {
            margin: 0 !important;
        }

        &__content {
            width: 100%;
            flex: 1 1 auto;
            padding: 16px 0 0;
            word-break: break-word;

            @include media(SL) {
                width: calc(100% - 200px);
                padding-top: 0;
                padding-left: 16px;
                padding-right: 0;
            }

            > p {
                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        img {
            margin: 0 !important;
            float: none !important;
        }
    }

    .wp-block-embed,
    .iframe { // For new embed and old
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            max-width: 100%;
        }

        &__wrapper {
            position: relative;

            &::before {
                content: '';
                display: block;
                padding-top: 50%;
            }

            &:has(iframe[src*="soundcloud"])::before {
                padding-top: 0;
                height: 166px;
            }
        }

        &.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
            padding-top: 42.85%;
        }
        &.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
            padding-top: 50%;
        }
        &.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
            padding-top: 56.25%;
        }
        &.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
            padding-top: 75%;
        }
        &.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
            padding-top: 100%;
        }
        &.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before {
            padding-top: 177.77%;
        }
        &.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
            padding-top: 200%;
        }
    }

    .iframe {
        &::before {
            content: '';
            display: block;
            padding-top: 56.25%;
        }
    }

    @media print {
        p,
        h2,
        h3,
        h4,
        h5,
        h6,
        img,
        iframe,
        figure,
        .wp-caption,
        .wp-caption-text {
            page-break-before: auto;
            page-break-after: auto;
            page-break-inside: avoid;
            display: block;
            position: relative;
        }
    }
}
