@import 'Styles/includes';

.HeroSlim {
    $root: &;

    width: 100%;
    position: relative;

    &--Default {
        background-color: $red-5;
    }

    &--Green {
        background-color: $green-5;
    }

    &__Bg {
        #{$root}--HasImage & {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            @include media(M) {
                max-height: none;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: black;
                opacity: 0.4;
            }
        }

        #{$root}--NoImage & {
            &::before {
                top: -510px;
                right: -1070px;
                transform: rotate(180deg);
            }
        }

        #{$root}--NoImage#{$root}--Default & {
            @extend %shell--before;
        }

        #{$root}--NoImage#{$root}--Green & {
            @extend %shell__green--before;

            &::before {
                top: -200px;
                left: 13%;
                width: 530px;
                transform: scaleY(-1);

                @include media(M) {
                    left: 33%;
                    width: 1000px;
                }
            }
        }

        #{$root}--NoImage#{$root}--Search & {
            &::before {
                top: -440px;
                left: -1057px;
                right: auto;
                transform: rotate(0deg);
            }
        }

        #{$root}--NoImage#{$root}--ArchiveFact & {
            &::before {
                top: -550px;
                left: 25%;

                @include media(M) {
                    top: -450px;
                    left: 50%;
                    width: 1050px;
                }
            }
        }

        #{$root}--NoImage#{$root}--HearingCare & {
            &::before {
                top: -550px;
                left: 25%;
                transform: rotateX(0);

                @include media(M) {
                    top: -605px;
                    left: 60%;
                    width: 1000px;
                }
            }
        }
    }

    &__Wrap {
        @include wrap;

        position: relative;
    }

    &__Container {
        position: relative;
        margin: 0 auto;

        #{$root}--ArchiveFact &,
        #{$root}--FactParent &,
        #{$root}--Search & {
            max-width: map-get($maxwidths, article);
        }

        #{$root}--HearingCare & {
            max-width: map-get($maxwidths, content);
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding: 60px 0;
        min-height: 300px;
        max-width: map-get($maxwidths, content);

        @include media(M) {
            padding: 80px 0;
            min-height: 330px;

            #{$root}--HearingCare & {
                padding: 60px 0;
                min-height: 208px;
            }
        }

        #{$root}--HasImage & {
            color: white;

            a {
                color: inherit;

                &:hover {
                    color: inherit;
                }
            }
        }

        #{$root}--Search & {
            padding: 40px 0;
            min-height: 212px;
            max-width: 866px;
            margin: 0 auto;

            @include media(M) {
                padding: 40px 0;
                min-height: 212px;
            }
        }

        #{$root}--NotFound & {
            padding-bottom: 0 !important;
            min-height: 0 !important;
        }
    }

    &__Title {
        @include textstyle(h1);
    }

    &__Text {
        :global {
            @extend %richtext;
        }

        @include textstyle(body-regular);

        margin-top: 16px;

        #{$root}--HearingCare & {
            display: none;
        }
    }

    &__SearchForm {
        position: absolute;
        bottom: -28px;
        left: 0;
        right: 0;

        #{$root}--Search & {
            max-width: 866px;
            margin: 0 auto;
        }

        #{$root}--NotFound & {
            max-width: map-get($maxwidths, content);
            margin-top: 16px;
            padding-bottom: 60px;
            position: relative;
            bottom: auto;
            left: auto;
            right: auto;

            @include media(M) {
                padding-bottom: 80px;
            }
        }
    }
}
