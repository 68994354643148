@import 'Styles/includes';

.Step3 {
    &__MemberNotice {
        background-color: $blue-10;
        color: $grey-80;
        padding: 11px 16px;
        font-size: 1.4rem;
        line-height: 1.4;
        margin-bottom: 24px;
    }

    &__ButtonAdd {
        margin-bottom: 24px;
    }

    &__Familymember {
        margin-bottom: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid $grey-30;

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        @include media(M) {
            margin-bottom: 0;
            margin-top: 24px;
            padding-bottom: 0;
            border-bottom: none;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__ButtonDelete {
        margin-top: 48px;

        @include media(L) {
            margin-top: 0;
            position: relative;
            bottom: 6px;
        }
    }

    &__Error {
        color: $red;
        font-size: 1.4rem;
        margin: 16px 0;
        line-height: 1.5;
    }
}
