@import 'Styles/includes';

.FactList {
    $root: &;

    &__Header {
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media(M) {
            padding: 24px 0;
        }
    }

    &__Title {
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 1.14;
        font-weight: $bold;
    }

    &__Expand {
        border: none;
        background-color: transparent;
        cursor: pointer;
        text-decoration: underline;
        font-size: 1.5rem;
        color: $red;
        position: relative;
        top: -2px;

        @include media(M) {
            font-size: 1.6rem;
        }
    }

    &__Children {
        margin: 16px 24px 32px;
    }
}
