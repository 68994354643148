@import 'Styles/includes';

.Share {
    $root: &;

    display: flex;
    flex-direction: column;
    padding-top: 24px;
    border-top: 1px solid $grey-20;

    @include media(M) {
        padding-top: 32px;
    }

    &__Title {
        @include textstyle(body-small);

        margin-bottom: 19px;

        @include media(M) {
            margin-bottom: 24px;
        }
    }

    &__List {
        display: flex;
        margin-left: -12px;
    }

    &__Item {
        flex: 0 0 auto;
        margin-left: 12px;
    }

    &__Link {
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        padding: 0;
        border-radius: 50%;
        background: $grey-90;
        transition: background $transition;

        &:not(#{$root}__Link--NoLink):hover {
            background: $red;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 20px;
            height: 20px;
            margin: auto;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        &--Facebook {
            &::after {
                top: -1px;
                left: -1px;
                width: 19px;
                height: 19px;
                background-image: url('#{$assetsPath}/img/facebook--white.svg') !important;
            }
        }

        &--Twitter::after {
            background-image: url('#{$assetsPath}/img/twitter--white.svg') !important;
        }

        &--Linkedin::after {
            top: -1px;
            width: 21px;
            height: 21px;
            background-image: url('#{$assetsPath}/img/linkedin--white.svg') !important;
        }
    }

}
