@import 'Styles/includes';

.CardOpinion {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 4px;
    background: $red-5;

    &__Link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        z-index: 1;
    }

    &__Content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 32px 16px;
        color: $grey-90;

        @include media(M) {
            padding: 32px 24px;
        }
    }

    &__Meta {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        font-size: 1.2rem;
        line-height: 1.4;
    }

    &__Term {
        color: $red;
        font-weight: bold;
        text-transform: uppercase;

        &::after {
            content: '';
            display: inline-block;
            margin: -3px 9px;
            width: 1px;
            height: 15px;
            background: $grey-90;
        }
    }

    &__Title {
        margin-bottom: auto;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.4;
    }

    &__Text {
        margin-top: 16px;
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 1.5;

        #{$root}:hover & {
            color: $red;
            text-decoration: underline;
        }
    }

    &__Button {
        @extend %button--round-download-white;

        position: absolute !important;
        bottom: 16px;
        right: 16px;

        @include media(M) {
            bottom: 24px;
            right: 24px;
        }

        #{$root}:hover & {
            @extend %button--round-download-white, :hover;
        }
    }
}
