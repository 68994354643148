%form {

    &__wrapper {
        margin-top: 16px;
    }

    &__label {
        display: block;
        position: relative;
        margin-bottom: 4px;
        font-size: 1.4rem;
        font-weight: bold;
        z-index: 1;

        &--inside {
            display: inline-block;
            transform: translateY(50%);
            margin-left: 16px;
            margin-bottom: 0;
            background-image: linear-gradient(#fff 50%, #fff 50%);
            padding: 0 5px;
            font-size: 1.2rem;
        }

        &--error {
            background-image: linear-gradient(#fff 50%, $red-5 50%) !important;
        }

        &--required {
            &::after {
                content: ' *';
                color: $red;
            }
        }

        a {
            color: $grey-80;
            text-decoration: underline;

            &:hover {
                color: $link-color;
            }
        }
    }

    &__label-checkbox,
    &__label-radio {
        display: block;
        position: relative;
        color: $grey-80;
        line-height: 1.5;
        font-size: 1.6rem;
        padding-left: 32px;
        margin-bottom: 10px;
        cursor: pointer;

        a {
            color: $grey-80;
            text-decoration: underline;

            &:hover {
                color: $link-color;
            }
        }

        &::before {
            content: '';
            border: 2px solid $grey-60;
            width: 20px;
            height: 20px;
            margin-right: 12px;
            display: block;
            position: absolute;
            top: 3px;
            left: 0;
            background-color: white;
            transition: border-color $transition;
        }

        &:hover {
            &::before {
                border-color: $blue-40;
            }
        }

        &--focus {
            &::before {
                border-color: $blue;
            }
        }

        &--checked {
            &::before {
                border-color: $blue;
            }
        }

        &--error {
            &::before {
                border-color: $red;
            }
        }
    }

    &__label-checkbox {
        border: 1px solid transparent;
        border-radius: 4px;

        &::before {
            border-radius: 2px;
        }

        &--checked {
            &::before {
                background-color: $blue;
                background-image: url(#{$assetsPath}/img/check--white.svg);
                background-size: 12px;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    &__label-radio {
        &::before {
            border-radius: 20px;
        }

        &--wrapped {
            background-color: white;
            padding: 11px 12px 11px 44px;
            border: 1px solid $grey-60;
            border-radius: 4px;
            transition: border-color $transition, background-color $transition;

            @include media(M) {
                padding: 15px 16px 15px 48px;
            }

            &:hover {
                border-color: $blue-40;
            }

            &::before {
                top: 0;
                bottom: 0;
                left: 12px;
                margin: auto;

                @include media(M) {
                    left: 16px;
                }
            }
        }

        &--checked {
            &::before {
                border-width: 7px;
            }
        }

        &--wrapped--checked {
            border-color: $blue;
            background-color: $blue-10;
        }

        &--wrapped--error {
            border-color: $red;
            background-color: $red-5;
        }
    }

    &__select-container {
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 40px;
            height: 44px;
            position: absolute;
            top: 1px;
            bottom: 1px;
            right: 3px;
            margin: auto;
            border-bottom: 1px solid $grey-40;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background: white url('#{$assetsPath}/img/chevron--grey-90.svg') no-repeat center center;
            background-size: 10px;
            transform: rotate(90deg);
        }
    }

    &__input,
    &__textarea,
    &__select,
    &__file {
        display: block;
        width: 100%;
        border-radius: 4px;
        border: 1px solid $grey-60;
        padding: 0 16px;
        font-size: 1.6rem;
        font-family: $base-font;
        background-color: #fff;
        outline: 0;
        transition: border-color $transition;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-text-fill-color: $grey-80;
            -webkit-box-shadow: 0 0 0 1000px #fff inset;
        }

        &:not([disabled]):hover {
            border-color: $blue-40;
        }

        &:not([disabled]):focus {
            border-color: $blue;
        }

        &::placeholder {
            color: $grey-60;
            opacity: 1;
        }

        &--error {
            background-color: $red-5;
            border-color: $red !important;
        }

        &[disabled] {
            background-color: $grey-20;
        }
    }

    &__input,
    &__select,
    &__file {
        height: 56px;
    }

    &__textarea,
    &__select[multiple] {
        height: auto;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &__select:not([multiple]) {
        padding-right: 45px;
        max-width: none;
    }

    &__file {
        padding: 16px;
    }

    &__dropzone {
        display: block;
        width: 100%;
        border-radius: 4px;
        border: 1px dashed $grey-60;
        padding: 24px 16px;
        font-size: 1.6rem;
        background-color: #fff;
        transition: border-color $transition;

        &:hover {
            border-color: $blue-40;
        }

        &:focus {
            border-color: $blue;
        }
    }

    &__checkbox,
    &__radio {
        position: absolute;
        opacity: 0;
        width: 20px;
        height: 20px;
    }

    &__description {
        margin-top: 8px;
        color: $grey-90;
        font-size: 1.4rem;
        line-height: 1.52;

        a {
            color: $grey-80;
            text-decoration: underline;

            &:hover {
                color: $link-color;
            }
        }
    }

    &__tooltip {
        display: inline-block;
        position: absolute;
        top: -1px;
        width: 16px;
        height: 16px;
        background-image: url(#{$assetsPath}/img/info--blue.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 5px;

        &--required {
            margin-left: 12px;
        }
    }

    &__error {
        display: block;
        position: relative;
        color: $grey-80;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.2;
        margin-top: 5px;
        padding-left: 22px;
        background-image: url(#{$assetsPath}/img/error--red.svg);
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: left center;
    }

    &__submit {
        @extend %button--blue;

        width: 100%;

        &--primary {
            @extend %button--primary;
        }
    }
}