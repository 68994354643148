@import 'Styles/includes';

.Error {
    @extend %form__error;

    position: absolute;

    &--OccupySpace {
        position: relative;
    }
}
