@import 'Styles/includes';

.SearchForm {
    $root: &;

    position: relative;
    display: flex;

    &__Field {
        flex: 1 1 auto;
    }

    &__Button {
        flex: 0 0 auto;
        min-width: 116px;

        #{$root} & {
            margin-left: 10px;
        }
    }
}
