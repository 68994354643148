@import 'Styles/includes';

.CardVideo {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 4px;
    background: $red-5;

    &__Link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        z-index: 2;
    }

    &__VideoPlayer {
        position: relative;
        width: 100%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        overflow: hidden;
    }

    &__Content {
        flex: 1 0 auto;
        position: relative;
        padding: 32px 16px 32px;
        color: $grey-90;

        @include media(M) {
            padding: 32px 24px 35px;
        }

        #{$root}--HasImage & {
            padding-top: 24px;
        }
    }

    &__Meta {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        font-size: 1.2rem;
        line-height: 1.4;
    }

    &__Term {
        color: $red;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__Title {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.4;
    }
}
