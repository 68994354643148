@import 'Styles/includes';

.Select {
    $root: &;

    margin-bottom: 32px;

    &--Full {
        width: 100%;
    }

    &__Label {
        #{$root}:not(#{$root}--LabelHidden) & {
            @extend %form__label;
            @extend %form__label--inside;

            z-index: 1;

            &--Required {
                @extend %form__label--required;
            }
        }

        #{$root}--Error & {
            @extend %form__label--error;
        }
    }

    &__Tooltip {
        @extend %form__tooltip;

        #{$root}__Label--Required & {
            @extend %form__tooltip--required;
        }
    }

    &__ReactTooltip {
        @extend %tooltip;
    }
}
