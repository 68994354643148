@import 'Styles/includes';

.ButtonDelete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    margin: 0;
    border: 2px solid $grey-80;
    border-radius: 4px;
    color: $grey-80;
    cursor: pointer;
    background-color: transparent;
    font-size: 1.6rem;
    font-weight: bold;
    transition: background-color 0.2s;
    padding: 0;

    &::after {
        content: '\002D';
        margin-left: 8px;
        color: $grey-80;

        @include media(ML) {
            content: none;
        }
    }

    @include media(L) {
        width: 24px;
        height: 24px;
        border: none;
        cursor: pointer;
        flex-shrink: 0;
        padding: 0;
        background-image: url(#{$assetsPath}/img/delete--grey-90.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        color: transparent;
        overflow: hidden;
    }
}
