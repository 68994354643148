%shell {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &--pseudo {
        content: '';
        display: block;
        position: absolute;
        width: 1632px;
        height: 1158px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    &--pseudo-red {
        @extend %shell--pseudo;

        background-image: url('#{$assetsPath}/img/shell--red.svg');
        opacity: .08;
    }

    &--pseudo-green {
        @extend %shell--pseudo;

        background-image: url('#{$assetsPath}/img/shell--green.svg');
    }

    &--before {
        @extend %shell;

        &::before {
            @extend %shell--pseudo-red;
        }
    }

    &--after {
        @extend %shell;

        &::after {
            @extend %shell--pseudo-red;
        }
    }

    &__green {
        &--before {
            @extend %shell;

            &::before {
                @extend %shell--pseudo-green;
            }
        }

        &--after {
            @extend %shell;

            &::after {
                @extend %shell--pseudo-green;
            }
        }
    }
}
