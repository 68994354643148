@import 'Styles/includes';

.FeaturedImage {
    $root: &;

    position: relative;
    margin: 0 auto;

    &--Small {
        max-width: map-get($maxwidths, content);
    }

    &--Large {
        max-width: map-get($maxwidths, article);
    }

    &__Figcaption {
        @include textstyle(caption);

        margin-top: 8px;

        @include media(M) {
            margin-top: 12px;
        }
    }

    &__Image {
        position: relative;
        margin: auto;

        &::before {
            content: '';
            display: none;
            width: 100%;
            height: 0;
            padding-bottom: 60%;

            @include media(M) {
                padding-bottom: 44%;
            }
        }

        img {
            display: block;
        }

        #{$root}--Large & {
            &::before {
                display: block;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
