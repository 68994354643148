@import 'Styles/includes';

.Step1 {
    &__SsnFieldContainer {
        position: relative;
    }

    &__SparButton {
        position: absolute;
        top: 36px;
        right: 16px;
        font-size: 1.4rem;
        border: none;
        background-color: transparent;
        cursor: pointer;
        padding: 0;
        color: $blue;

        &:disabled {
            opacity: 0.5;
        }
    }

    &__ManualInputButtonContainer {
        margin-bottom: 32px;
    }

    &__ManualInputButton {
        color: $grey-80;
        background-color: transparent;
        text-decoration: underline;
        font-size: 1.4rem;
        cursor: pointer;
        border: none;
        padding: 0;
    }

    &__SparError {
        margin: 6px 0;
        padding: 8px 10px;
        color: $error-color;
        font-size: 1.4rem;
        background-color: $error-bg;
    }

    &__SparLoader {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $blue-10;
        width: 100%;
        min-height: 158px;
        margin-bottom: 65px;
    }
}
