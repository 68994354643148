@import 'Styles/includes';

.ButtonNext {
    $root: &;

    &::after {
        content: '';
        width: 20px;
        height: 15px;
        display: inline-block;
        margin-left: 10px;
        background-image: url(#{$assetsPath}/img/arrow-large--white.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: rotate(90deg);
    }
}
