@import 'Styles/includes';

.ButtonAdd {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    margin: 0;
    border: 2px solid $blue;
    border-radius: 4px;
    color: $blue;
    cursor: pointer;
    background-color: transparent;
    font-size: 1.6rem;
    font-weight: bold;
    transition: background-color 0.2s;
    padding: 0;

    &::after {
        content: '\002B';
        margin-left: 8px;
        color: $blue;
    }
}
