@import 'Styles/includes';

.HearingCareSelect {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &__Form {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
    }

    &__Select {
        flex: 1 0 auto;
        margin: 0 !important;
    }

    &__Button {
        flex: 0 0 auto;
        width: 56px;
        height: 56px;
        margin-left: 16px !important;

        &::after {
            content: '';
            display: inline-block;
            position: relative;
            top: -2px;
            width: 20px;
            height: 14px;
            margin: -2px 0 -2px -6px;
            background: url('#{$assetsPath}/img/arrow-large--white.svg') no-repeat center center;
            background-size: contain;
        }
    }
}
