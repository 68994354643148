@import 'Styles/includes';

.FactsExplorer {
    $root: &;
    padding: 48px 0;
    position: relative;

    @include media(M) {
        padding: 80px 0;
    }

    &--HideTopShell {
        padding-top: 0 !important;
    }

    &__Bg {
        @extend %shell--before;
        background-color: $red-5;
        position: absolute;
        inset: 0;

        &::before {
            display: none !important;

            @include media(L) {
                display: block !important;
                right: -850px;
                top: -600px;
            }

            #{$root}--HideTopShell & {
                display: none !important;
            }
        }
    }

    &__Wrap {
        @include wrap;
        position: relative;
    }

    &__Title {
        @include textstyle(h2);
        margin-bottom: 24px;

        @include media(M) {
            margin-bottom: 40px;
        }
    }

    &__Subtitle {
        font-size: 1.4rem;
        font-weight: $bold;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    &__SearchForm {
        margin-bottom: 48px;
        max-width: 640px;
    }

    &__List {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;

        @include media(M) {
            grid-template-columns: 1fr 1fr;
            gap: 38px;
        }

        @include media(L) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    &__Item {
        background-color: #fff;
        border-radius: 4px;
        border: solid 1px $grey-30;
        min-width: 0;

        &:last-child {
            background-color: $red;
            border-color: $red;
            color: #fff;
        }
    }

    &__ItemLink {
        display: flex;
        flex-direction: column;
        min-height: 200px;
        padding: 16px;

        @include media(M) {
            min-height: 245px;
            padding: 24px;
        }
    }

    &__ItemTitle {
        @include textstyle(preamble);
        font-weight: $bold;
        margin-bottom: 12px;

        #{$root}__Item:last-child & {
            font-size: 2.4rem;
            color: #fff;
        }
    }

    &__ItemText {
        @include textstyle(body-small);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @include media(M) {
            -webkit-line-clamp: 4;
        }
    }

    &__ItemArrow {
        @extend %button--round-arrow-pink;
        margin-top: auto !important;

        #{$root}__Item:hover & {
            @extend %button--round-arrow-pink, :hover;
        }

        #{$root}__Item:last-child & {
            @extend %button--round-arrow-white;
            margin-top: auto !important;
        }

        #{$root}__Item:last-child:hover & {
            border-color: #fff;
            background-color: #fff;

            svg {
                fill: $red;
            }
        }
    }
}
