/*
    You don't need to extend every class for button, just include the one you need
    Example:
        For primary button, @extend %button--primary
        For primary button with arrow, @extend %button--primary-icon
        For round chevron, @extend %button--round-chevron-small
 */
%button {
    position: relative;
    font-size: 1.6rem;
    line-height: 1.3;
    min-height: 48px;
    padding: 13px 24px;
    text-decoration: none;
    font-family: $base-font;
    border: 1px solid;
    border-radius: 4px;
    box-shadow: none;
    cursor: pointer;
    transition: border $transition, color $transition, background $transition;

    // Display can't be overriden on cookie buttons
    &:not(#cookiescript_accept):not(#cookiescript_reject):not(#cookiescript_save) {
        display: inline-block;
    }

    svg {
        display: inline-block;
        position: relative;
        top: 1px;
        width: 16px;
        height: 16px;
        margin-left: 8px;
        transition: fill $transition;
    }

    &--smallM {
        @include media-max(M) {
            padding: 10px 20px;
            font-size: 1.4rem;
            min-height: 42px;
        }
    }

    &--primary {
        @extend %button;

        color: white;
        font-weight: bold;
        border-color: $red;
        background: $red;

        svg {
            fill: white;
        }

        &:not([disabled]):hover {
            border-color: $red-60;
            background: $red-60;
        }

        &-disabled,
        &[disabled] {
            border-color: $grey-60;
            background: $grey-60;
        }

        &-link {
            @extend %button--primary;

            &::after {
                content: '';
                display: inline-block;
                position: relative;
                top: -2px;
                width: 12px;
                height: 12px;
                margin: -2px 0 -2px 8px;
                background: url('#{$assetsPath}/img/arrow-small--white.svg') no-repeat center center;
                background-size: contain;
            }

            @include href-external {
                &::after {
                    top: 0;
                    width: 16px;
                    height: 16px;
                    background-image: url('#{$assetsPath}/img/external--white.svg');
                }
            }
        }
    }

    &--secondary {
        @extend %button;

        color: $grey-90;
        font-weight: bold;
        border-color: $grey-90;
        background: transparent;

        svg {
            fill: $grey-90;
        }

        &:not([disabled]):hover {
            color: white;
            border-color: $red;
            background: $red;

            svg {
                fill: white;
            }
        }

        &-disabled,
        &[disabled] {
            color: $grey-60;
            border-color: $grey-60;
            background: transparent;

            svg {
                fill: $grey-60;
            }
        }

        &-link {
            @extend %button--secondary;

            &::after {
                content: '';
                display: inline-block;
                position: relative;
                top: -2px;
                width: 12px;
                height: 12px;
                margin: -2px 0 -2px 8px;
                background: url('#{$assetsPath}/img/arrow-small--grey-90.svg') no-repeat center center;
                background-size: contain;
                transition: background-image $transition;
            }

            &:not([disabled]):hover::after {
                background-image: url('#{$assetsPath}/img/arrow-small--white.svg');
            }

            &[disabled]::after {
                background-image: url('#{$assetsPath}/img/arrow-small--grey-60.svg');
            }

            @include href-external {
                &::after {
                    top: 0;
                    width: 16px;
                    height: 16px;
                    background-image: url('#{$assetsPath}/img/external--grey-90.svg');

                }

                &:not([disabled]):hover::after {
                    background-image: url('#{$assetsPath}/img/external--white.svg');
                }

                &[disabled]::after {
                    background-image: url('#{$assetsPath}/img/external--grey-60.svg');
                }
            }
        }
    }

    &--tertiary {
        @extend %button;

        color: white;
        font-weight: bold;
        border-color: white;
        background: transparent;

        svg {
            fill: white;
        }

        &:hover {
            color: $grey-90;
            background: white;

            svg {
                fill: $grey-90;
            }
        }

        &-link {
            @extend %button--tertiary;

            &::after {
                content: '';
                display: inline-block;
                position: relative;
                top: -2px;
                width: 12px;
                height: 12px;
                margin: -2px 0 -2px 8px;
                background: url('#{$assetsPath}/img/arrow-small--white.svg') no-repeat center center;
                background-size: contain;
                transition: background-image $transition;
            }

            &:hover::after {
                background-image: url('#{$assetsPath}/img/arrow-small--grey-90.svg');
            }

            @include href-external {
                &::after {
                    top: 0;
                    width: 16px;
                    height: 16px;
                    background-image: url('#{$assetsPath}/img/external--white.svg');

                }

                &:hover::after {
                    background-image: url('#{$assetsPath}/img/external--grey-90.svg');
                }
            }
        }
    }

    &--blue {
        @extend %button;

        min-height: 56px;
        padding: 17px 24px;
        color: white;
        font-weight: bold;
        border-color: $blue;
        background: $blue;

        svg {
            fill: white;
        }

        &:not([disabled]):hover {
            border-color: $blue-70;
            background: $blue-70;
        }

        &-disabled,
        &[disabled] {
            border-color: $grey-50;
            background: $grey-50;
            cursor: auto;

            &:hover {
                border-color: $grey-50;
                background: $grey-50;
            }
        }
    }

    &--back {
        @extend %button;

        min-height: 0;
        padding: 7px 12px;
        color: $grey-90;
        font-size: 1.4rem;
        font-weight: $bold;
        line-height: 1.2;
        border-color: white;
        background: white;
        box-shadow: 0 2px 10px 0 rgba(black, .08);

        &:not([disabled]):hover {
            color: white;
            border-color: $red;
            background: $red;
        }
    }

    &--tag {
        @extend %button;

        color: $grey-90;
        border-color: $grey-40;
        background: transparent;

        &:hover {
            color: $grey-90;
            border-color: $grey-90;
            background: transparent;
        }

        &-active {
            color: white;
            border-color: $grey-90;
            background: $grey-90;
        }
    }

    &--round-chevron {
        @extend %button;

        width: 40px;
        height: 40px;
        min-height: 40px;
        padding: 0;
        border-color: $red;
        border-radius: 50%;
        background: $red;

        @include media(M) {
            width: 48px;
            height: 48px;
        }

        svg {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 9px;
            height: 13px;
            margin: auto;
            fill: white;
            transition: fill $transition;
        }

        &:not([disabled]):hover {
            border-color: $red-60;
            background: $red-60;
        }

        &-disabled,
        &[disabled] {
            border-color: $grey-30;
            background: $grey-30;

            svg {
                fill: $grey-70;
            }

            &:hover {
                border-color: $grey-30;
                background: $grey-30;

                svg {
                    fill: $grey-70;
                }
            }
        }
    }

    &--round-link {
        @extend %button;

        width: 32px;
        height: 32px;
        min-height: 32px;
        padding: 0;
        border-radius: 50%;
        border-color: $red;
        background: $red;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 15px;
            height: 11px;
            margin: auto;
            background: transparent no-repeat center center;
            background-size: contain;
        }

        &-arrow {
            @extend %button--round-link;

            &::before {
                background-image: url('#{$assetsPath}/img/arrow-large--white.svg');
            }

            &-large {
                @extend %button--round-link-arrow;

                width: 40px;
                height: 40px;
                min-height: 40px;

                &::before {
                    width: 18px;
                    height: 14px;
                }
            }
        }

        &-external {
            @extend %button--round-link;

            &::before {
                width: 18px;
                height: 18px;
                background-image: url('#{$assetsPath}/img/external--white.svg');
            }

            &-large {
                @extend %button--round-link-external;

                width: 40px;
                height: 40px;
                min-height: 40px;

                &::before {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &--round-arrow {
        @extend %button;

        width: 40px;
        height: 40px;
        min-height: 40px;
        padding: 0;
        border-radius: 50%;

        svg {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 17px;
            height: 14px;
            margin: auto;
            transition: fill $transition;
        }

        &-small {
            width: 32px;
            height: 32px;
            min-height: 32px;

            svg {
                width: 15px;
                height: 11px;
            }
        }

        &-white {
            @extend %button--round-arrow;

            border-color: white;
            background: white;

            svg {
                fill: $red;
            }

            &:hover {
                border-color: $red;
                background: $red;

                @media(hover: none) {
                    border-color: white;
                    background: white;
                }

                svg {
                    fill: white;

                    @media(hover: none) {
                        fill: $red;
                    }
                }
            }

            &-small {
                @extend %button--round-arrow-white;
                @extend %button--round-arrow-small;
            }
        }

        &-red {
            @extend %button--round-arrow;

            border-color: $red;
            background: $red;

            svg {
                fill: white;
            }

            &:hover {
                border-color: white;
                background: white;

                @media(hover: none) {
                    border-color: $red;
                    background: $red;
                }

                svg {
                    fill: $red;

                    @media(hover: none) {
                        fill: white;
                    }
                }
            }

            &-small {
                @extend %button--round-arrow-red;
                @extend %button--round-arrow-small;
            }
        }

        &-pink {
            @extend %button--round-arrow;

            border-color: $red-5;
            background: $red-5;

            svg {
                fill: $red;
            }

            &:hover {
                border-color: $red;
                background: $red;

                @media(hover: none) {
                    border-color: $red-5;
                    background: $red-5;
                }

                svg {
                    fill: white;

                    @media(hover: none) {
                        fill: $red;
                    }
                }
            }

            &-small {
                @extend %button--round-arrow-pink;
                @extend %button--round-arrow-small;
            }
        }

        &-transparent {
            @extend %button--round-arrow;

            border-color: transparent;
            background: transparent;

            svg {
                fill: $red;
            }

            &:hover {
                border-color: #fff;
                background: #fff;
            }

            &-small {
                @extend %button--round-arrow-transparent;
                @extend %button--round-arrow-small;
            }
        }
    }

    &--round-download {
        @extend %button;

        width: 40px;
        height: 40px;
        min-height: 40px;
        padding: 0;
        border-radius: 50%;

        svg {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 18px;
            height: 18px;
            margin: auto;
            transition: fill $transition;
        }

        &-white {
            @extend %button--round-download;

            border-color: white;
            background: white;

            svg {
                fill: $red;
            }

            &:hover {
                border-color: $red;
                background: $red;

                svg {
                    fill: white;
                }
            }
        }
    }
}
