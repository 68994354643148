@import 'Styles/includes';

.FactChildren {
    $root: &;

    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @include media(M) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
    }

    &__ItemArrow {
        @extend %button--round-arrow-pink;

        #{$root}__Item:hover & {
            @extend %button--round-arrow-pink, :hover;
        }
    }

    &__Link {
        @include textstyle(body-regular);
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: $red-5;
        border-radius: 4px;
        min-height: 64px;
        padding: 12px 16px 12px 24px;
    }
}
