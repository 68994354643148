@import 'Styles/includes';

.GutenbergContent {
    $root: &;

    @include textstyle(body-regular);

    &__Block {
    }

    &__Richtext {
        :global {
            @extend %richtext;
        }

        #{$root}__Block:first-child & {
            > *:first-child {
                margin-top: 0 !important;
            }
        }

        #{$root}__Block:last-child & {
            > *:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}
