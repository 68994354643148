@import 'Styles/includes';

.Field {
    $root: &;

    margin-bottom: 24px;

    &--Search {
        margin: 0;
    }

    &--Third {
        width: calc(100% / 3);
    }

    &--Full {
        width: 100%;
    }

    &__Label {
        @extend %form__label;
        @extend %form__label--inside;

        #{$root}--Error & {
            @extend %form__label--error;
        }

        #{$root}--Required & {
            @extend %form__label--required;
        }
    }

    &__Tooltip {
        @extend %form__tooltip;

        #{$root}--Required & {
            @extend %form__tooltip--required;
        }
    }

    &__ReactTooltip {
        @extend %tooltip;
    }

    &__Field {
        @extend %form__input;

        #{$root}--Error & {
            @extend %form__input--error;
        }

        #{$root}--Search & {
            border-color: $grey-30;
            box-shadow: 0 4px 16px 0 rgba(black, .1);

            &:focus {
                border-color: black;
            }
        }
    }
}
