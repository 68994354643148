@import 'Styles/includes';

.Spar {
    margin-bottom: 35px;

    &__Content {
        background-color: $blue-10;
        padding: 13px 16px;
        margin-bottom: 8px;
    }

    &__Title {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 2px;
    }

    &__Info {
        margin-bottom: 14px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__GdprText {
        p {
            font-size: 1.4rem;
        }

        a {
            color: $grey-80;
            text-decoration: underline;
            font-size: 1.4rem;
        }
    }

    &__Error {
        font-size: 1.4rem;
        color: $red;
    }
}
